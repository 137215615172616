import * as React from "react"
import { UserContext } from "../../providers/UserProvider"
import AdminPage from "../admin/AdminPage"
import { LoadingOutlined } from "@ant-design/icons"
import SignIn from "./SignIn"
import UserPage from "../user/UserPage"
import ForgotPassword from "./ForgotPassword"

interface Props {}

const LoadingPage: React.FC<Props> = () => {
  return (
    <div className="container">
      <div className="row justify-content-center">
        <div style={{ position: "absolute", top: "40%" }}>
          <LoadingOutlined
            className="text-center"
            style={{ fontSize: 125, color: "var(--primary-color)" }}
            spin
          />
        </div>
      </div>
    </div>
  )
}

const AuthenticationWrapper: React.FC<Props> = () => {
  //@ts-ignore
  const { user, auth } = React.useContext(UserContext)
  const [showForgotPassword, setShowForgotPassword] = React.useState(false)

  const toggleForgotPassword = () => {
    setShowForgotPassword(!showForgotPassword)
  }

  return user === undefined ? (
    <LoadingPage />
  ) : //If user is false, it is not yet know, display loading screen
  user === null ? (
    showForgotPassword == false ? (
      <SignIn auth={auth} toggleForgotPassword={toggleForgotPassword}/>
    ) : (
      <ForgotPassword auth={auth!} toggleForgotPassword={toggleForgotPassword}/>
    )
  ) : user.admin == true ? (
    <AdminPage />
  ) : (
    <UserPage />
  )
}

export default AuthenticationWrapper
