import { faCloudUploadAlt, faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Col, Row } from 'antd/lib/grid';
import * as React from 'react';
import FilePreview from '../shared/FilePreviewTable';
import FileUploadBox from './FileUploadBox';

interface IFileManagementProps {
    selectedUser: User,
    submitFile: ((file: File, uid: String) => Promise<any>) | undefined
}

const FileManagement: React.FunctionComponent<IFileManagementProps> = ({ selectedUser, submitFile }) => {
    const [selectedMode, setSelectedMode] = React.useState<"View" | "Upload">("View");


    return <div style={{ height: "100%" }} className="bordered p-3">
        {/* Title */}
        <Row justify="center" style={{ height: "10%" }}>
            <Col span={24} className="text-center">
                <h3
                    style={{
                        fontWeight: "bold",
                        color: "black",
                        paddingTop: "10px",
                    }}
                >
                    File Management
        </h3>
            </Col>
            <Col span={24} className="text-center ">
                <p>
                    Upload, modify and delete files associated with a user
                    selected on the LHS here
        </p>
            </Col>
        </Row>

        <Row
            align="middle"
            style={{ height: "11.5%" }}
            className="bordered pl-5 pr-5"
        >
            <Col span={12} style={{ color: "black" }}>
                <span>Name : {selectedUser?.displayName}</span>
                <br />
                <span>Email : {selectedUser?.email}</span>
                <br />
                <span>Company : {selectedUser?.companyName}</span>
            </Col>
            <Col span={12} className="text-right">
            </Col>
        </Row>
        <div style={{ height: "1%" }}></div>
        <Row
            style={{ height: "10%" }}
        >
            <Col flex="auto"
                onClick={() => { setSelectedMode("View") }}
                className={`file-management-mode ${selectedMode === "View" ? "bordered-selected" : "bordered-hoverable"}`}>
                <Row align="middle" justify="center" style={{ height: "100%" }}>
                    <Col>
                        View/Delete Current Files
            <i className="ml-5">
                            <FontAwesomeIcon icon={faSearch} />
                        </i>
                    </Col>
                </Row>
            </Col>
            <Col flex="auto"
                onClick={() => { setSelectedMode("Upload") }}
                className={`ml-2 file-management-mode ${selectedMode === "Upload" ? "bordered-selected" : "bordered-hoverable"}`}>
                <Row align="middle" justify="center" style={{ height: "100%" }}>
                    <Col>
                        Upload Files for User
            <i className="ml-5">
                            <FontAwesomeIcon icon={faCloudUploadAlt} />
                        </i>
                    </Col>
                </Row>
            </Col>
        </Row>
        {/* File Management View */}
        <Row
            justify="center"
            style={{ height: "67.5%", overflowY: "scroll", width: "100%" }}
            className="bordered p-2 mt-2 text-center"
        >
            {selectedUser != null ? (selectedMode == "View" ? <FilePreview adminMode={true} uid={selectedUser.uid} /> : <Col span={24}><FileUploadBox key={selectedUser.email} submitFile={submitFile!} selectedUser={selectedUser} /></Col>
            ) :
                <h3 className="text-center">Please select a user in order to manage their selected files</h3>}
        </Row>
    </div>
};

export default FileManagement;
