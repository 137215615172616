import Dragger, { DraggerProps } from 'antd/lib/upload/Dragger';
import * as React from 'react';
import { InboxOutlined } from "@ant-design/icons";
import { message } from 'antd';

interface Props {
  selectedUser: User,
  submitFile: (file: File, uid: string) => Promise<any>,
}

const FileUploadBox: React.FC<Props> = (props) => {
  //Get access to storage from the UserProvider

  const FileUploadConfig: DraggerProps = {
    name: 'file',
    multiple: true,
    customRequest: async ({ file, onSuccess, onError }) => {
      //Attempt to add the file to firebase ...
      try {
        const result = await props.submitFile(file, props.selectedUser.uid)
        onSuccess!(null, result)
      }
      catch (e) {
        onError!(e)
      }

    },
    onChange(info: any) {
      const { status } = info.file;
      if (status !== 'uploading') {
        console.log(info.file, info.fileList);
      }
      if (status === 'done') {
        message.success(`${info.file.name} file uploaded successfully.`);
      } else if (status === 'error') {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
  }

  return (
    <div style={{ height: "300px" }}>
      <Dragger {...FileUploadConfig}>
        <p className="ant-upload-drag-icon">
          <InboxOutlined className="ant-upload-drag-icon" />
        </p>
        <p className="ant-upload-text">
          Click or drag file to this area to upload
            </p>
        <p className="ant-upload-hint">
          Support for a single or bulk upload.
            </p>
      </Dragger>
    </div>);
}

export default FileUploadBox