import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import Form from "antd/lib/form"
import FormItem from "antd/lib/form/FormItem"
import Input from "antd/lib/input"
import Modal from "antd/lib/modal"
import * as React from "react"
import { faEnvelope, faUser } from "@fortawesome/free-solid-svg-icons"
import { useForm } from "antd/lib/form/Form"
import scorchFireLogo from "../../images/scorch-fire-logo.png"
import Row from "antd/lib/row"
import firebase from "gatsby-plugin-firebase"
import { Button } from "antd"

interface AddUserModalProps {
  showModal: boolean
  toggleModal: () => void
}

const AddUserModal: React.FC<AddUserModalProps> = ({
  showModal,
  toggleModal,
}) => {
  const [form] = useForm()
  const [loading, setLoading] = React.useState<boolean>(false)
  const [errorText, setErrorText] = React.useState<string>("")


  const createUser = async (event: any) => {
    event.preventDefault();
    setLoading(true)
    //Defines a callable function for making firebase requests
    const firebaseCall = firebase
      .app()
      .functions('australia-southeast1')
      .httpsCallable("createNewUser");
    //Validate inputs..
    try {
      const values = await form.validateFields()
      //Make call to the api
      let response = await firebaseCall({
        displayName: values.displayName,
        email: values.email,
        company: values.companyName
      })
      console.log(response);
      if (response.data.success === false) {
        setErrorText(`There was an error creating the account : ${response.data.error?.name}`)
        setLoading(false)
      }
      else {
        setLoading(false)
        toggleModal()
      }
    } catch (e) {
      console.log(e)
      setErrorText(`There was an error creating the account : ${e.message}`)
      setLoading(false)
    }
  }

  return (
    <Modal
      title=""
      footer={null}
      visible={showModal}
      onCancel={() => {
        toggleModal()
      }}
    >
      <form>
        <Form form={form} wrapperCol={{ span: 24 }} labelCol={{ span: 24 }}>
          <Row justify="center">
            <img
              className="col-6 pb-2 text-center"
              src={scorchFireLogo}
              alt="Scorch Fire Logo"
            />
          </Row>
          <p style={{ color: "red" }}>{errorText}</p>
          <Row justify="center">
            <p style={{ fontSize: "12px" }}>
              Create a user by inputting the user details below, ensure all
              details are correct before proceeding.
            </p>
          </Row>
          <FormItem
            label="Display Name:"
            required
            name="displayName"
            style={{ fontSize: "18px" }}
            rules={[
              {
                required: true,
                message: "Please enter a valid display name",
              },
            ]}
          >
            <div className="input-container justify-content-center">
              <i className="icon">
                <FontAwesomeIcon icon={faUser} />
              </i>
              <Input
                placeholder="Input user display name here"
                autoComplete="off"
              />
            </div>
          </FormItem>
          <FormItem
            label="Company Name:"
            required
            name="companyName"
            style={{ fontSize: "18px" }}
            rules={[
              {
                required: true,
                message: "Please enter a valid company name",
              },
            ]}
          >
            <div className="input-container justify-content-center">
              <i className="icon">
                <FontAwesomeIcon icon={faUser} />
              </i>
              <Input
                placeholder="Input company name here"
                autoComplete="off"
              />
            </div>
          </FormItem>
          <FormItem
            required
            label="User Email:"
            name="email"
            style={{ fontSize: "18px" }}
            rules={[
              {
                required: true,
                type: "email",
                message: "Please enter a valid email address",
              },
            ]}
          >
            <div className="input-container justify-content-center">
              <i className="icon">
                <FontAwesomeIcon icon={faEnvelope} />
              </i>
              <Input
                placeholder="Input user email here"
                autoComplete="off"
              />
            </div>
          </FormItem>

          <div className="row justify-content-center p-1">
            <Button
              type="primary"
              style={{ fontFamily: "Plain" }}
              onClick={createUser}
              loading={loading}
            >
              Create User
            </Button>
          </div>
        </Form>
      </form>
    </Modal>
  )
}

export default AddUserModal
