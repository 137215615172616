import * as React from "react"
import scorchFireLogo from "../../images/scorch-fire-logo.png"
import "../../styles/SignIn.css"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import firebase from "gatsby-plugin-firebase"
import "firebase/auth"
import {
  faEnvelope,
  faChevronLeft,
} from "@fortawesome/free-solid-svg-icons"
import Form, { useForm } from "antd/lib/form/Form"
import FormItem from "antd/lib/form/FormItem"
import Input from "antd/lib/input/Input"
import message from "antd/lib/message"
import "antd/lib/message/style/index.css"
import { Row } from "antd/lib/grid"

interface Props {
  auth: firebase.auth.Auth
  toggleForgotPassword : ()=>void
}

const ForgotPassword: React.FC<Props> = props => {
  const [form] = useForm()

  async function handlePasswordReset(
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) {
    const validationResult = await form.validateFields()

    if (validationResult != null) {
      //If form is valid attempt firebase sign in
      await props.auth
        .sendPasswordResetEmail(
          form.getFieldValue("email")
        )
        .catch((error: Error) => {
          message.info(
            "An error occured sending a reset email, please contact support."
          )
          console.error("Error occured sending reset email", error)
          return
        })
        message.success(
          "A recovery email has been sent to the email if it exists !"
        )
    }

    console.log("Authenticated !")
  }

  return (
    <div className="container text-center vertical-center justify-content-center">
      <div className="row justify-content-center pl-5 pr-5 pt-5 pb-2">
        <form className="sign-in-modal border rounded col-md-8 col-lg-6 col-xl-6 ">
          <Form form={form}>
            <Row>
              <a href = "#" className="pl-2 pt-3" onClick={props.toggleForgotPassword}>
                <i>
                  <FontAwesomeIcon icon={faChevronLeft} /> <span>Sign In</span>
                </i>
              </a>
            </Row>
            <img
              className="col-6 pb-5"
              src={scorchFireLogo}
              alt="Scorch Fire Logo"
            />
            <p>
              Enter your account email address below to recover your password:
            </p>
            <FormItem
              required
              name="email"
              style={{ fontSize: "18px" }}
              rules={[
                {
                  required: true,
                  type: "email",
                  message: "Please enter a valid email address",
                },
              ]}
            >
              <div className="input-container justify-content-center">
                <i className="icon">
                  <FontAwesomeIcon icon={faEnvelope} />
                </i>
                <Input className="col-9" placeholder="Email" type="email" />
              </div>
            </FormItem>

            <div className="row justify-content-center pb-5">
              <button
                style={{ fontFamily: "Plain" }}
                className="btn sign-in-btn bordered mt-3"
                onClick={event => {
                  handlePasswordReset(event)
                }}
              >
                Send Recovery Email
              </button>
            </div>
          </Form>
        </form>
      </div>
    </div>
  )
}
export default ForgotPassword
