import * as React from "react"
import UserProvider, { UserContext } from "../providers/UserProvider"
import AuthenticationWrapper from "../components_dynamic/shared/AuthenticationWrapper"
import { Router } from "@reach/router"
import { RouteComponentProps } from "@reach/router"

interface Props extends RouteComponentProps {}

export const App: React.FC<Props> = () => {
  const user = React.useContext(UserContext)
  console.log(user)

  return (
    <Router>
      <DynamicApp path = "/app/client"/>
    </Router>
  )
}

const DynamicApp: React.FC<Props> = () => {
  return (
    <UserProvider>
      <AuthenticationWrapper/>
    </UserProvider>
  )
}
export default App
