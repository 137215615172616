import { Row, Col } from "antd"
import * as React from "react"
import { UserContext } from "../../providers/UserProvider"
import "antd/dist/antd.css"
import Navbar from "../../components_static/Navbar"
import Layout, { Content, Header } from "antd/lib/layout/layout"
import ClientSelection from "./ClientSelection"
import FileManagement from "./FileManagement"

interface Props { }

const AdminPage: React.FC<Props> = (): JSX.Element => {
  //@ts-ignore
  const { auth, submitFile} = React.useContext(
    UserContext
  );
  const [selectedUser, setSelectedUser] = React.useState<any>(null);
  const [showModal, setShowModal] = React.useState<boolean>(false);

  const toggleModal = () => {
    setShowModal(!showModal)
  }

  return (
    // Navbar
    <div style = {{minWidth: "1200px"}}>
    <Layout style={{ height: "100vh", minHeight: "800px"}}>
      <Header style={{ height: "109px" }}>
        <Navbar signedIn={true} auth= {auth}/>
      </Header>
      <Content style={{ backgroundColor: "white" }}>
        <Row style={{ height: "100%" }} justify="space-between">
          {/* Client Selection Form */}
          <Col span={6} className="p-3" style={{ height: "100%" }}>
            <ClientSelection
              setSelectedUser={setSelectedUser}
              showModal={showModal}
              toggleModal={toggleModal}
              selectedUser={selectedUser}
            />
          </Col>
          {/* File Management Selection */}
          <Col span={18} className="p-3" style={{ height: "100%" }}>
            <FileManagement selectedUser={selectedUser} submitFile={submitFile}/>
          </Col>
        </Row>
      </Content>
    </Layout>
    </div>
  )
}
export default AdminPage
