import { Input } from "antd"
import Button from "antd/lib/button"
import { Row } from "antd/lib/grid"
import firebase from "gatsby-plugin-firebase"
import "firebase/firestore"
import * as React from "react"
import AddUserModal from "./AddUserModal"

interface ClientSelectionProps {
  toggleModal: () => void
  showModal: boolean
  selectedUser: User,
  setSelectedUser: (user: User) => void
}

const ClientSelection: React.FC<ClientSelectionProps> = ({
  toggleModal,
  setSelectedUser,
  selectedUser,
  showModal,
}) => {
  const [filterText, setFilterText] = React.useState<string>("")
  const [users, setUsers] = React.useState<Array<User>>([]);
  const filterClientList = (value: React.ChangeEvent<HTMLInputElement>) => {
    setFilterText(value.target.value)
  }
  //Fetch all users from firebase, updates on snapshot
  React.useEffect(() => {
    firebase.firestore().collection("users").onSnapshot((querySnapshot) => {
      let allUsers: Array<User> = [];
      querySnapshot.forEach((doc: any) => {
        const data = doc.data()
        const uid = doc.id
        allUsers.push({ displayName: data.displayName, email: data.email, uid: uid, admin: data.admin, companyName: data.companyName })
      })
      setUsers(allUsers);
    })
  }, [])

  return (
    <div style={{ height: "100%" }} className="bordered p-3">
      {/* Title */}
      <Row justify="center" style={{ height: "5%" }}>
        <h3
          style={{
            fontWeight: "bold",
            color: "black",
            paddingTop: "10px",
          }}
        >
          Client Selection
        </h3>
      </Row>
      {/* Create User Button */}
      <Row justify="center">
        <Button
          className="sign-in-btn mb-2 mt-2"
          style={{ borderRadius: "10px", width: "100%", height: "5%" }}
          onClick={() => {
            toggleModal()
          }}
        >
          Create New User
        </Button>
        <AddUserModal showModal={showModal} toggleModal={toggleModal} />
      </Row>

      <div
        style={{ height: "calc(90%)", overflowY: "scroll" }}
        className="bordered p-2"
      >
        {/* User Search Bar */}
        <Input
          name="searchClient"
          placeholder="Search Client By Email"
          style={{ borderRadius: "10px" }}
          onChange={event => {
            filterClientList(event)
          }}
        />
        {/* List all of the users using cards */}
        {users != null
          ? users
            .filter(user => {
              if (
                (user.admin === false) &&
                (user.email.toLowerCase().includes(filterText.toLowerCase()) ||
                user.displayName
                  .toLowerCase()
                  .includes(filterText.toLowerCase()))
              ) {
                return user
              } else {
                return null
              }
            })
            .map((user,index) => {
              return (
                <div
                  key = {index}
                  className={`p-2 mt-2 mb-2 select-user ${selectedUser?.email === user.email ? "bordered-selected" : "bordered-hoverable"}`}
                  style={{ color: "black" }}
                  onClick={() => {
                    setSelectedUser(user)
                  }}
                >
                  <span>Name : {user.displayName}</span>
                  <br />
                  <span>Email : {user.email}</span>
                </div>
              )
            })
          : ""}
      </div>
    </div>
  )
}

export default ClientSelection
