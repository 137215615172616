import * as React from "react"
import { UserContext } from "../../providers/UserProvider"
import { message, Popconfirm, Table } from "antd";
import BrokenImageLink from '../../images/scorch-fire-logo.png'
import { Button } from "antd/lib/radio";


interface Props {
  uid: String
  adminMode: boolean
}

const FilePreview: React.FC<Props> = ({ uid, adminMode }) => {
  const { getAllFilesForUser, deleteDocumentByAddress } = React.useContext(UserContext)
  const [currentUserFiles, setCurrentUserFiles] = React.useState<Array<any>>([]);

  React.useEffect(() => {
    getAllFilesForUser!(uid).then(userFiles => setCurrentUserFiles(userFiles.map((file: any) => {
      //Check the extension, if it is not .
      let thumbnail = file[0];
      if (file[1].split('.')[1] == undefined || !(/png|PNG|jpg|JPG|JPEG/.test(file[1].split('.')[1].toUpperCase()))) {
        thumbnail = BrokenImageLink;
      }
      return {
        key: file[1],
        address: file[2],
        fileName: file[1],
        delete: <Popconfirm title="Are you sure you wish to delete this file ?" onConfirm={() => { handleDocumentDeletion!(file[2]) }} onCancel={() => { }}><Button className="delete-button">Remove File</Button></Popconfirm>,
        thumbnail: <a href={file[0]} download={file[0]}><img style={{ height: '75px' }} src={thumbnail}></img></a>
      }
    }
    ).sort((a : any,b: any)=>{
      return a.fileName.localeCompare(b.fileName)
    })))
  }, [uid])

  const handleDocumentDeletion = async (address: string) => {
    //Attempt Delete
    let resultBool = await deleteDocumentByAddress!(address);
    //Check result
    if (resultBool == true) {
      setCurrentUserFiles((prevUserFiles) => {
        let userFiles = prevUserFiles.filter((currentFile) => {
          if (currentFile.address === address) {
            return false
          } else {
            return true
          }
        });
        return userFiles;
      })
    } else {
      message.warning("Document deletion was unsuccessful")
    }

  }

  const getTableColumns = (): any => {
    let tableColumns = [
      { title: 'Your Files', dataIndex: 'fileName', key: 'fileName' },
      { title: 'Download', dataIndex: 'thumbnail', key: 'thumbnail', align: 'center' }];
    if (adminMode == true) {
      tableColumns.push({ title: 'Delete', dataIndex: 'delete', key: 'delete', align: 'center' })
    }

    return tableColumns;
  }

  return (
    <>{currentUserFiles.length == 0 ? <div></div> :
      <Table className="file-table" style={{ width: "100%" }} dataSource={currentUserFiles} columns={getTableColumns()} />}
    </>
  )
}

export default FilePreview
