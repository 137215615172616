import * as React from "react"
import scorchFireLogo from "../../images/scorch-fire-logo.png"
import "../../styles/SignIn.css"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faEnvelope,
  faKey,
  faChevronLeft,
} from "@fortawesome/free-solid-svg-icons"
import Form, { useForm } from "antd/lib/form/Form"
import FormItem from "antd/lib/form/FormItem"
import Input from "antd/lib/input/Input"
import message from "antd/lib/message"
import "antd/lib/message/style/index.css"
import { Row } from "antd/lib/grid"
import { Link } from "gatsby"

interface Props {
  auth: any
  toggleForgotPassword : ()=>void
}

const SignIn: React.FC<Props> = props => {
  const [form] = useForm()

  async function handleSignIn(
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) {
    const validationResult = await form.validateFields()

    if (validationResult != null) {
      //If form is valid attempt firebase sign in
      await props.auth
        .signInWithEmailAndPassword(
          form.getFieldValue("email"),
          form.getFieldValue("password")
        )
        .catch((error: Error) => {
          message.info(
            "An error occured signing in, no accounts exist matching that email and password"
          )
          console.error("Error signing in with password and email", error)
          return
        })
    }

    console.log("Authenticated !")
  }

  return (
    <div className="container text-center vertical-center justify-content-center">
      <div className="row justify-content-center pl-5 pr-5 pt-5 pb-2">
        <form className="sign-in-modal border rounded col-md-8 col-lg-6 col-xl-6 ">
          <Form form={form}>
            <Row>
              <Link to="/" className="pl-2 pt-3">
                <i>
                  <FontAwesomeIcon icon={faChevronLeft} /> <span>Home</span>
                </i>
              </Link>
            </Row>
            <img
              className="col-6 pb-5"
              src={scorchFireLogo}
              alt="Scorch Fire Logo"
            />
            <p>
              Please sign in to scorchfire by providing your credentials below:
            </p>
            <FormItem
              required
              name="email"
              style={{ fontSize: "18px" }}
              rules={[
                {
                  required: true,
                  type: "email",
                  message: "Please enter a valid email address",
                },
              ]}
            >
              <div className="input-container justify-content-center">
                <i className="icon">
                  <FontAwesomeIcon icon={faEnvelope} />
                </i>
                <Input className="col-9" placeholder="Email" type="email" />
              </div>
            </FormItem>
            <FormItem
              required
              name="password"
              style={{ fontSize: "18px" }}
              rules={[
                {
                  required: true,
                  validator: async (_, value) => {
                    if (value.length < 6) {
                      return Promise.reject(
                        "The password must be at least 6 characters long"
                      )
                    }
                    return Promise.resolve()
                  },
                  message: "The password must be at least 6 characters long",
                },
              ]}
            >
              <div className="input-container justify-content-center">
                <i className="icon">
                  <FontAwesomeIcon icon={faKey} />
                </i>
                <Input
                  className="col-9"
                  placeholder="Password"
                  type="password"
                />
              </div>
            </FormItem>

            <div className="row justify-content-center p-5">
              <button
                style={{ fontFamily: "Plain" }}
                className="col-4 btn sign-in-btn bordered mt-3"
                onClick={event => {
                  handleSignIn(event)
                }}
              >
                Sign in
              </button>
            </div>
          </Form>
        </form>
      </div>
      <div className="row justify-content-center pl-5 pr-5">
        <div className="row justify-content-center sign-in-modal border rounded col-md-8 col-lg-6 col-xl-6">
          <p>
            Forgot your password ?{" "}
            <a style = {{fontWeight : "bold"}}className="forgot-password" href="#" onClick={props.toggleForgotPassword}>
              click here
            </a>
          </p>
        </div>
      </div>
    </div>
  )
}

export default SignIn
