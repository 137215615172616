import { Component, createContext } from "react"
import firebase from "gatsby-plugin-firebase"
import "firebase/auth"
import "firebase/storage"
import "firebase/firestore"
import * as React from "react"
import { UserContextInterface } from "../interfaces/UserContext"

export const UserContext = createContext<UserContextInterface>({
  user: undefined,
  auth: null,
  firestore: null,
  storage: null,
  getAllFilesForUser: null,
  deleteDocumentByAddress: null,
})

interface UserProviderState {
  user: User | undefined | null
  auth: firebase.auth.Auth
  firestore: firebase.firestore.Firestore
  storage: firebase.storage.Storage
}

class UserProvider extends Component<{}, UserProviderState> {
  state = {
    user: undefined,
    auth: firebase.auth(),
    firestore: firebase.firestore(),
    storage: firebase.storage(),
  }

  constructor(props: any) {
    super(props)
    this.submitFile = this.submitFile.bind(this)
  }

  /**
   * Initilises the UserProvider state, fetches the auth, firestore and storage instances
   * from firestore and checks if a user is currently logged in
   */
  componentDidMount = async () => {
    //Set auth,storage,firestore so it become available to children
    this.state.auth.onAuthStateChanged(async userAuth => {
      const user = await this.generateUserDocument(
        userAuth,
        null,
        this.state.firestore
      )
      this.setState({ user })
    })
  }

  /**
   * This method gets the user document associated with a specific user id
   *
   * @param {*} uid
   * @returns
   */
  getUserDocument = async (
    uid: any,
    firestore: firebase.firestore.Firestore
  ): Promise<User | null> => {
    if (!uid) return null
    try {
      const userDocument = await firestore.doc(`users/${uid}`).get()
      return {
        uid,
        email: userDocument.data()!.email,
        displayName: userDocument.data()!.displayName,
        admin: userDocument.data()!.admin,
        companyName: userDocument.data()!.companyName
      }
    } catch (error) {
      console.error("Error fetching user", error)
      return null
    }
  }

  deleteDocumentByAddress = async (address: string): Promise<boolean> => {
    // Create a reference to the file to delete
    var deleteRef = await this.state.storage.ref(address)
    // Delete the file
    try {
      await deleteRef.delete();
      console.log("delete successful")
      return true
    } catch (error) {
      console.log(error)
      return false;
    }
  }

  generateUserDocument = async (
    user: any,
    additionalData: any,
    firestore: firebase.firestore.Firestore
  ) => {
    if (user == null) {
      return null;
    } else {
      const userRef = firestore.doc(`users/${user.uid}`)
      const snapshot = await userRef.get()
      if (!snapshot.exists) {
        const { email, displayName, photoURL } = user
        try {
          await userRef.set({
            displayName,
            email,
            photoURL,
            ...additionalData,
          })
        } catch (error) {
          console.error("Error creating user document", error)
        }
      }
      return this.getUserDocument(user.uid, firestore)
    }
  }

  submitFile: (file: File, userEmail: string) => Promise<any> = async (
    file,
    uid
  ) => {
    const storageRef = await this.state.storage.ref(`/${uid}`)
    const fileRef = storageRef.child(file.name) //Use thr name of file retrieved from input
    //Generate unique name IMPLEMENT LATER
    //Use the file ref to actually upload the data...
    try {
      const returnfile = fileRef
        .put(file)
        .then(() => console.log("Uploaded the file."))
      return Promise.resolve(returnfile)
    } catch (e) {
      return Promise.reject(e)
    }
  }

  getAllFilesForUser: (uid: string) => Promise<any> = async (uid) => {
    try {
      const fileList: firebase.storage.ListResult = await this.state.storage
        .ref(`/${uid}`)
        .listAll()
      const filesArray = fileList.items
      const allURLPromise = filesArray.map(async file => {
        return [await file.getDownloadURL(), file.name, file.fullPath]
      })
      const allURLResolved = await Promise.all(allURLPromise)
      return Promise.resolve(allURLResolved)
    } catch (e) {
      return Promise.reject(e)
    }
  }

  render() {
    const user = this.state.user
    const auth = this.state.auth
    const firestore = this.state.firestore
    const storage = this.state.storage
    const submitFile = this.submitFile
    const getAllFilesForUser = this.getAllFilesForUser
    const deleteDocumentByAddress = this.deleteDocumentByAddress


    return (
      <UserContext.Provider
        value={{
          user,
          submitFile,
          getAllFilesForUser,
          deleteDocumentByAddress,
          auth,
          firestore,
          storage,
        }}
      >
        {this.props.children}
      </UserContext.Provider>
    )
  }
}

export default UserProvider
