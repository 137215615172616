import { Col, Row } from 'antd';
import Layout from 'antd/lib/layout';
import { Content, Header } from 'antd/lib/layout/layout';
import * as React from 'react';
import Navbar from '../../components_static/Navbar';
import { UserContext } from '../../providers/UserProvider';
import FilePreview from '../shared/FilePreviewTable';

interface IUserPageProps {

}

const UserPage: React.FunctionComponent<IUserPageProps> = (props) => {
  const { user, auth } = React.useContext(
    UserContext
  );
  return (<Layout style={{ height: "100vh", minHeight: "800px" }}>
    <Header style={{ height: "109px" }}>
      <Navbar signedIn={true} auth={auth}/>
    </Header>
    <Content style={{ backgroundColor: "white" }}>
      <Row style={{ height: "100%" }} justify="space-between">
        {/* User File Viewer */}
        <Col span={24} className="p-1 p-lg-3" style={{ height: "100%" }}>
          {/* Title */}
          <div style={{ height: "100%" }} className="bordered p-3">
            <Row justify="center" style={{ height: "15%" }}>
              <Col span={24} className="text-center">
                <h3
                  style={{
                    fontWeight: "bold",
                    color: "black",
                    paddingTop: "10px",
                  }}
                >
                  {user?.displayName}
              </h3>
              </Col>
              <Col span={24} className="text-center ">
                <p>
                  {user?.companyName}
                </p>
              </Col>
            </Row>
            <Row
              justify="center"
              style={{ height: "85%", overflowY: "scroll", width: "100%" }}
              className="bordered p-2 mt-2 text-center"
            >
              <FilePreview adminMode={false} uid={user!.uid} />
            </Row>
          </div>
        </Col>
      </Row>
    </Content>
  </Layout>);
};

export default UserPage;
